import { useEffect, useState, useContext } from "react";
import {
  FormContainer, FormItem, StyledInput, PButton, StyledTextArea, EEAT,
  EEATWRAP,
  EEATText,
  StyledLabel,
  StyledSelect,
} from "./styled";
import { Row, Col, Form, Input } from "antd";
import Loading from "../../../components/Loading";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";
import Image from 'next/image';
import { jsonData } from "../../../components/LocationsMap/_data";
import { LocationContext } from "../../../context/LocationContext";

export default function FormConsultationSmall({
  handleSubmit,
  form,
  theme,
  loading,
  referralCode,
  showModal,
  setShowModal,
  submitButton,
  dispatchDestination,
  formId,
  darkLabels,
  isFranchiseForm
}) {
  const [urlParams, setUrlParams] = useState(null);
  const [selectedShowroom, setSelectedShowroom] = useState(null);
  const [showroomData, setShowroomData] = useState(null);
  const location = useContext(LocationContext);

  // Filter locations from jsonData - include all non-dealer locations
  const showroomOptions = jsonData.features
    .filter(location => {
      // Skip dealer locations
      if (location.properties.dealer === 'yes') return false;
      // Ensure we have valid city and state
      return location.properties.city && location.properties.state;
    })
    .map(location => {
      const city = location.properties.city.trim();
      const state = location.properties.state.trim();
      return {
        value: `${city}, ${state}`,
        label: `${city}, ${state}`,
        data: {
          ...location,
          properties: {
            ...location.properties,
            city,
            state
          }
        }
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));  // Sort alphabetically

  // Watch for location changes and update showroom selection
  useEffect(() => {
    if (location && !selectedShowroom) {
      // Get user's actual location from localStorage
      let userLocation = { city: '', state: '' };
      try {
        const storedLocation = localStorage.getItem('usersLocationData');
        if (storedLocation) {
          userLocation = JSON.parse(storedLocation);
        }
      } catch (error) {
        console.error('Error reading user location:', error);
      }

      // Get closest showroom from localStorage
      let closestShowroom = null;
      try {
        const storedShowroom = localStorage.getItem('locationFetched');
        if (storedShowroom) {
          closestShowroom = JSON.parse(storedShowroom);
          const showroomValue = `${closestShowroom.properties.city}, ${closestShowroom.properties.state}`;
          const matchingShowroom = showroomOptions.find(option => 
            option.value === showroomValue
          );

          if (matchingShowroom) {
            console.log('Setting initial showroom from location:', matchingShowroom.data);
            setSelectedShowroom(showroomValue);
            setShowroomData(matchingShowroom.data);
            
            form.setFieldsValue({
              showroom: showroomValue,
              tenantId: matchingShowroom.data.properties.tenantId,
              city: userLocation.city || '',
              state: userLocation.state || ''
            });
          }
        }
      } catch (error) {
        console.error('Error reading closest showroom:', error);
      }
    }
  }, [location, form, selectedShowroom, showroomOptions]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window?.location?.search;
      const urlParams = new URLSearchParams(queryString);
      if (queryString) {
        let obj = {};
        const entries = urlParams.entries();
        for (const entry of entries) {
          obj[entry[0]] = entry[1];
        }
        setUrlParams(obj);
      }
    }

    return () => {
      setUrlParams(null);
    };
  }, []);

  const handleShowroomChange = (value) => {
    const selectedOption = showroomOptions.find(option => option.value === value);
    if (selectedOption && location?.userLocation) {
      console.log('Selected showroom:', selectedOption.data);
      setSelectedShowroom(value);
      setShowroomData(selectedOption.data);
      
      // Keep user's actual location when changing showroom
      form.setFieldsValue({ 
        showroom: value,
        tenantId: selectedOption.data.properties.tenantId,
        city: location.userLocation?.city || '',
        state: location.userLocation?.state || ''
      });
    }
  };

  const onFinish = (values) => {
    // For franchise forms, don't require showroom selection
    if (isFranchiseForm) {
      const submissionData = {
        ...values,
        ...urlParams,
        tenantId: 1  // Default to tenant 1 for franchise forms
      };

      console.log('Form values:', values);
      console.log('Final submission data:', submissionData);

      // Google Analytics event
      if (window.gtag) {
        window.gtag('event', 'generate_lead', {
          event_category: 'Form',
          event_label: 'Consultation Form',
          value: 1
        });
      }

      handleSubmit(submissionData);
      return;
    }

    // Regular form handling with showroom
    const selectedOption = showroomOptions.find(option => option.value === values.showroom);
    if (!selectedOption) {
      console.error('No showroom selected');
      return;
    }

    // Get user's actual location from localStorage
    let userLocation = { city: '', state: '' };
    try {
      const storedLocation = localStorage.getItem('usersLocationData');
      console.log('Retrieved user location from localStorage:', storedLocation);
      if (storedLocation) {
        userLocation = JSON.parse(storedLocation);
        console.log('Parsed user location:', userLocation);
      } else {
        console.warn('No user location found in localStorage');
      }
    } catch (error) {
      console.error('Error reading user location:', error);
    }

    // Create submission data object with user's actual location
    const submissionData = {
      ...values,
      ...urlParams,
      // User's actual location
      user_city: userLocation.city || '',
      user_state: userLocation.state || '',
      // Franchise/showroom location
      city: selectedOption.data.properties.city.trim(),
      state: selectedOption.data.properties.state,
      tenantId: selectedOption.data.properties.tenantId,
      selectedShowroom: values.showroom // Keep track of selected showroom separately
    };

    console.log('Form values:', values);
    console.log('User location:', userLocation);
    console.log('Showroom location:', selectedOption.data.properties);
    console.log('Final submission data:', submissionData);

    // Google Analytics event
    if (window.gtag) {
      window.gtag('event', 'generate_lead', {
        event_category: 'Form',
        event_label: 'Consultation Form',
        value: 1
      });
    }

    handleSubmit(submissionData);
  };

  return (
    <div>
      <FormContainer 
        name={formId} 
        size="small" 
        onFinish={onFinish}
        form={form}
        layout="vertical"
      >
        <FormItem
          name="full_name"
          rules={[{ required: true, message: "Full Name required" }]}
          label={<StyledLabel color="black">Full Name</StyledLabel>}
        >
          <StyledInput placeholder="Jane/John Doe" />
        </FormItem>
        <FormItem
          name="email"
          rules={[
            { required: true, message: "Email required" },
            { type: "email", message: "Not a valid email" }
          ]}
          label={<StyledLabel>Email Address</StyledLabel>}
        >
          <StyledInput placeholder="user@email.com" />
        </FormItem>
        <FormItem
          name="phone"
          rules={[{ required: true, message: "Phone required" }]}
          label={<StyledLabel>Phone Number</StyledLabel>}
          normalize={(value) => {
            if (!value) return value;
            const onlyNums = value.replace(/[^\d]/g, "");
            if (onlyNums.length >= 4 && onlyNums.length <= 6) {
              return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
            }
            if (onlyNums.length > 6 && onlyNums.length <= 10) {
              return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
            }
            if (onlyNums.length > 10) {
              return `(${onlyNums.slice(1, 4)}) ${onlyNums.slice(4, 7)}-${onlyNums.slice(7, 11)}`;
            }
            return onlyNums;
          }}
        >
          <StyledInput placeholder="202-555-5555" />
        </FormItem>
        {!isFranchiseForm && (
          <FormItem
            name="showroom"
            rules={[{ required: true, message: "Please select a showroom" }]}
            label={<StyledLabel>Nearest Showroom</StyledLabel>}
          >
            <StyledSelect
              placeholder="Select Showroom"
              options={showroomOptions}
              onChange={handleShowroomChange}
            />
          </FormItem>
        )}
        {!isFranchiseForm && (
          <FormItem name="tenantId" hidden>
            <Input type="hidden" />
          </FormItem>
        )}
        <FormItem
          name="message"
          label={<StyledLabel>Message</StyledLabel>}
        >
          <StyledTextArea placeholder="Tell us about your organization dreams!" rows={3} />
        </FormItem>
        <FormItem>
          <PButton
            ptype="primary"
            pname={submitButton || "GET STARTED"}
            width="100%"
            bgColor={theme.colors.prune}
            hoverBgColor={theme.colors.hoverColor2}
            height="20px"
            shadow="yes"
            htmlType="submit"
          />
        </FormItem>
      </FormContainer>
      <Loading loading={loading} />
    </div>
  );
}
