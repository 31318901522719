import { useEffect, useState } from "react";
import {
  FormContainer,
  FormItem,
  StyledInput,
  PButton,
  StyledTextArea,
  StyledSelect,
  EEAT,
  EEATWRAP,
  EEATText,
} from "./styled";
import { Row, Col } from "antd";
import Loading from "../../../components/Loading";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";
import Typography from "../../../components/Typography";
import { jsonData } from "../../../components/LocationsMap/_data";
import Image from "next/image";

// Filter out dealer locations and create showroom options
const createShowroomOptions = () => {
  return jsonData.features
    .filter(location => {
      if (location.properties.dealer === 'yes') return false;
      return location.properties.city && location.properties.state;
    })
    .map(location => {
      const city = location.properties.city.trim();
      const state = location.properties.state.trim();
      return {
        value: `${city}, ${state}`,
        label: `${city}, ${state}`,
        data: {
          ...location,
          properties: {
            ...location.properties,
            city,
            state
          }
        }
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
};

function FormConsultationLarge({
  handleSubmit,
  form,
  theme,
  loading,
  failed,
  referralCode,
  showModal,
  setShowModal,
}) {
  const [urlParams, setUrlParams] = useState(null);
  const [selectedShowroom, setSelectedShowroom] = useState(null);
  const [showroomData, setShowroomData] = useState(null);
  const showroomOptions = createShowroomOptions();

  useEffect(() => {
    // Get user's actual location from localStorage
    try {
      const storedLocation = localStorage.getItem('usersLocationData');
      if (storedLocation) {
        const userLocation = JSON.parse(storedLocation);
        form.setFieldsValue({
          user_city: userLocation.city || '',
          user_state: userLocation.state || ''
        });
      }
    } catch (error) {
      console.error('Error reading user location:', error);
    }

    // Get closest showroom from localStorage
    try {
      const storedShowroom = localStorage.getItem('locationFetched');
      if (storedShowroom) {
        const closestShowroom = JSON.parse(storedShowroom);
        const showroomValue = `${closestShowroom.properties.city}, ${closestShowroom.properties.state}`;
        const matchingShowroom = showroomOptions.find(option => 
          option.value === showroomValue
        );

        if (matchingShowroom) {
          setSelectedShowroom(showroomValue);
          setShowroomData(matchingShowroom.data);
          form.setFieldsValue({
            showroom: showroomValue,
            tenantId: matchingShowroom.data.properties.tenantId
          });
        }
      }
    } catch (error) {
      console.error('Error reading closest showroom:', error);
    }

    // Handle URL parameters
    if (typeof window !== "undefined") {
      const queryString = window?.location?.search;
      const urlParams = new URLSearchParams(queryString);
      if (queryString) {
        let obj = {};
        const entries = urlParams.entries();
        for (const entry of entries) {
          obj[entry[0]] = entry[1];
        }
        setUrlParams(obj);
        form.setFieldsValue({
          email: urlParams.get("email"),
        });
      }
    }

    return () => {
      setUrlParams(null);
    };
  }, [form, showroomOptions]);

  const handleShowroomChange = (value) => {
    const selectedOption = showroomOptions.find(option => option.value === value);
    if (selectedOption) {
      setSelectedShowroom(value);
      setShowroomData(selectedOption.data);
      form.setFieldsValue({ 
        showroom: value,
        tenantId: selectedOption.data.properties.tenantId
      });
    }
  };

  const onFinish = async (values) => {
    // Get user's actual location from localStorage
    let userLocation = { city: '', state: '' };
    try {
      const storedLocation = localStorage.getItem('usersLocationData');
      if (storedLocation) {
        userLocation = JSON.parse(storedLocation);
      }
    } catch (error) {
      console.error('Error reading user location:', error);
    }

    // Get showroom location from selected option
    const selectedOption = showroomOptions.find(option => option.value === values.showroom);
    if (!selectedOption) {
      console.error('No showroom selected');
      return;
    }

    const submissionData = {
      ...values,
      // User's actual location
      user_city: userLocation.city,
      user_state: userLocation.state,
      // Franchise/showroom location
      city: selectedOption.data.properties.city.trim(),
      state: selectedOption.data.properties.state,
      tenantId: selectedOption.data.properties.tenantId,
      selectedShowroom: values.showroom
    };

    handleSubmit(submissionData);
  };

  return (
    <div>
      <ModalContactSuccess
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      />
      <FormContainer
        name="large-form"
        size="small"
        onFinish={onFinish}
        form={form}
      >
        <Row className="background-red" gutter={16}>
          <Col span={24}>
            <FormItem
              validateTrigger={"onBlur"}
              name={"email"}
              rules={[
                {
                  required: true,
                  message: "Email required",
                },
                {
                  type: "email",
                  message: "Not a valid email",
                },
              ]}
            >
              <StyledInput placeholder={"Email address"} />
            </FormItem>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <FormItem
                  name={"showroom"}
                  rules={[
                    {
                      required: false,
                      message: "Showroom required",
                    },
                  ]}
                >
                  <StyledSelect
                    placeholder="Select Showroom*"
                    optionFilterProp="children"
                    onChange={handleShowroomChange}
                    options={showroomOptions}
                  />
                </FormItem>
              </Col>
            </Row>
            <FormItem initialValue={referralCode} name={"referral_code"}>
              <StyledInput placeholder={"Promotional Code"} $primary={true} />
            </FormItem>
          </Col>
        </Row>
        <div aria-hidden="true" tabIndex={"-1"} className="form-bot1">
          <StyledInput placeholder="username" />
        </div>
        <FormItem>
          <PButton
            ptype="primary"
            pname="GET STARTED"
            width="100%"
            bgColor={theme.colors.prune}
            hoverBgColor={theme.colors.hoverColor2}
            height="20px"
            shadow="yes"
            htmlType="submit"
            role="link"
          />
        </FormItem>
        <EEATWRAP>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-01.svg"
              width="32px"
              height="44px"
              className="iconImage"
              alt="40+ years experience"
            >
            </Image>
            <EEATText>40+ Years<br />Experience</EEATText>
          </EEAT>
          <EEAT>   
            <Image
              src="/images/eeat/EEAT-02.svg"
              width="32px"
              height="44px"
              className="iconImage"
              alt="lifetime limited warranty"
            >
            </Image>
            <EEATText>Limited<br /> Lifetime Warranty</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-03.svg"
              width="32px"
              height="44px"
              className="iconImage"
              alt="A+ BBB Rating"
            >
            </Image>
            <EEATText>A+ BBB<br />Rating</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-04.svg"
              width="32px"
              height="44px"
              className="iconImage"
              alt="custom fit"
            >
            </Image>
            <EEATText>Custom<br />Fit</EEATText>
          </EEAT>
        </EEATWRAP>
      </FormContainer>
      <Loading loading={loading} />
      <Typography>{failed}</Typography>
    </div>
  );
}

export default FormConsultationLarge;
