import { useState } from "react";
import { Form } from "antd";
import { withTheme } from "styled-components";
import FormConsultationSmall from "./small";
import FormConsultationLarge from "./large";
import ClassicFormConsultation from "../Consultation-Classic";
import { setGoogleAdwordsInfo } from "../../../utils/helper";
import useFetch from "../../../hooks/useFetch";
import { useCookies } from "react-cookie";
import { getQueryFromStorage } from "../../../hooks/useQueryCatcher";
import TagManager from "react-gtm-module";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";

const CRM_URL = "/api/contact";
const EMAIL_URL = "/api/contact/email";

const FormConsultation = ({ 
  theme, 
  variant, 
  referralCode, 
  submitButton, 
  dispatchDestination = "contact", 
  formId, 
  darkLabels, 
  initialValues,
  isFormReady = true, // Default to true for backward compatibility
  onSuccess,
  form: externalForm
}) => {
  const [cookie, setCookie] = useCookies(["converted"]);
  const { submitMultiple } = useFetch();
  // Always call the hook, but only use it if no external form is provided
  const [internalForm] = Form.useForm();
  const form = externalForm || internalForm;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <>
      <ClassicFormConsultation
        variant={variant}
        dispatchDestination={dispatchDestination}
        submitButton={submitButton}
        formId={formId}
        referralCode={referralCode}
        darkLabels={darkLabels}
        showModal={showModal}
        setShowModal={setShowModal}
        form={form}
        initialValues={initialValues}
        isFormReady={isFormReady}
        onSuccess={onSuccess}
      />
      <ModalContactSuccess 
        open={showModal} 
        onRequestClose={handleCloseModal}
      />
    </>
  );
};

export default withTheme(FormConsultation);
